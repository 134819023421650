// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------UI--------------- */
import makeStyles from '@material-ui/core/styles/makeStyles';

/* -----------------Assets--------------- */
import AppLogo from '@images/memorang_logo.svg';

/* -----------------Styles--------------- */
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
  },
  imageContainer: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: '100%',
    borderRadius: '50%',
    position: 'absolute',
    marginTop: -48,
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      marginTop: 0,
    },
  },
  notImageContainer: {
    flex: 1,
    overflow: 'hidden',
    paddingTop: theme.spacing(3),
    width: '100%',
    '& > div': {
      willChange: 'transform, opacity',
      width: '100%',
      display: 'inline-block',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  appLogo: {
    maxWidth: 75,
    padding: 8,
  },
}));

/* -----------------Props--------------- */
type Props = {
  children: *,
};

/* -----------------Component--------------- */
const AuthContainer = (props: Props) => {
  const classes = useStyles();
  const {
    children,
  } = props;
  return (
    <div className={classes.container}>
      <div className={classes.paper}>
        <div className={classes.imageContainer}>
          <img className={classes.appLogo} alt="memorang" src={AppLogo} />
        </div>
        <div className={classes.notImageContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
