// @flow
/* ------------------- Globals ------------------- */
import React, { useEffect, useState } from 'react';

/* ----------------------- 3rd Party ------------------ */
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { TextField } from 'formik-material-ui';
import { I18n } from '@aws-amplify/core';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import axios from 'axios';
import API from '@aws-amplify/api';

/* -----------------Context--------------- */
import {
  PageContext,
  pageVariant,
} from '../common/PageContext';

/* -----------------Configs--------------- */
import setUpAmplifyConfig from '@components/config/amplify-web-config';

/* ------------------- Schema ------------------- */
import {
  signUpValidationSchema,
  signInValidationSchema,
  forgotPasswordValidationSchema,
} from 'common/auth/validationSchema';

/* -----------------------  Constants ------------------ */
import {
  buttonTitle,
  placeholder,
} from 'common/auth/constants';

/* ------------------- Actions ------------------- */
import {
  signUp,
  forgotPasswordVerification,
  signIn,
  signInUsingSocialAuth,
  verifyUserEmail,
} from '@components/actions/auth';
import awsExports from 'common/src/config/aws-exports';
import { getErrorMessage, getInitialValues } from 'common/auth/functions';
import {
  store,
  key,
} from '../../common/authutils';
import {
  handleSignInNavigation,
} from '../../navigationUtils';

/* -----------------------  Icon ------------------ */
import DefaultAvatar from '../../../../public/static/splash/AvatarSample.png';

/* ------------------- Components ------------------- */
import MergeUserAccount from './MergeUserAccount';

/* ------------------- Styles ------------------- */
const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(2, 0, 2),
    borderRadius: 50,
    height: 48,
  },
}));

type Props = {
  variant: string,
  refreshAuth?: *,
  setEnableChangePassword?: ({success?: boolean, error?: boolean, username?: string}) => void,
  disableSocialLogin?: (v: boolean) => void,
};

const AuthInput = (props: Props) => {
  const {
    variant,
    refreshAuth,
    setEnableChangePassword,
    disableSocialLogin,
  } = props;
  const classes = useStyles();
  const [schema, setSchema] = useState(null);
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setpassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [migratedUser, setMigratedUser] = useState(false);
  const [matchedUsername, setMatchedUsername] = useState('');
  const [promptFlag, setPromptFlag] = useState(false);
  const [existingLogin, setExistingLogin] = useState([]);

  const { variant: variantFromContext } = React.useContext(PageContext);

  const clearError = () => setErrorMessage(null);

  const updateAWSConfig = () => {
    API.configure(setUpAmplifyConfig());
  };
  useEffect(() => {
    if (variant === 'signup') {
      setSchema(signUpValidationSchema);
    } else if (variant === 'login') {
      setSchema(signInValidationSchema);
    } else {
      setSchema(forgotPasswordValidationSchema);
    }
  }, [variant]);

  const getActionName = () => {
    if (variant === 'signup') {
      return buttonTitle.signUp;
    } if (variant === 'login') {
      return buttonTitle.signIn;
    }
    return buttonTitle.forgotPassword;
  };

  const updateCredentials = (usernameValue: string, passwordValue: string, emailValue: string) => {
    setUsername(usernameValue);
    setpassword(passwordValue);
    setEmail(emailValue);
  };

  const updateAuth = () => {
    if (typeof refreshAuth === 'function') {
      refreshAuth();
    }
  };

  const mergeUserAccounts = async () => {
    try {
      await axios.post(awsExports.UpdateMergeFlagApi, { username: matchedUsername, migratedUser });
      if (username && password && email) {
        await signUp(username, password, email);
        setIsSubmitting(false);
        store(key.loggedIn, 'true');
        store(key.userIconUrl, DefaultAvatar);
        updateAuth();
        handleSignInNavigation(pageVariant.static === variantFromContext);
      } else {
        store(key.loggedIn, 'true');
      }
    } catch (err) {
      if (typeof err === 'object') {
        setErrorMessage(err.message);
      } else {
        setErrorMessage(err);
      }
    }
  };

  const handleUserMergeOnError = (err) => {
    if (err.code === 'UserLambdaValidationException') {
      const message = decodeURIComponent(err.message);
      const parsedData = message.substring(
        message.lastIndexOf('[') + 1,
        message.lastIndexOf(']') - 1,
      );
      if (!parsedData) {
        const error = getErrorMessage(err);
        setErrorMessage(error);
        return;
      }
      const {
        username: usernameToMerge,
        migratedUser: migratedUserFlag,
        existingLogins,
        error,
      } = JSON.parse(parsedData);

      if (error === 'userAlreadyExistsException' || error === 'emailAlreadyExistsException') {
        const errMsg = getErrorMessage(err);
        setErrorMessage(errMsg);
      } else {
        setMatchedUsername(usernameToMerge);
        setMigratedUser(migratedUserFlag);
        setExistingLogin(existingLogins);
        setPromptFlag(true);
        setErrorMessage(message);
      }
    } else {
      const error = getErrorMessage(err);
      setErrorMessage(error);
    }
  };

  const handleSocialLogin = async (socialAuthType) => {
    try {
      await signInUsingSocialAuth(socialAuthType);
      await store(key.loggedIn, 'true');
      await store(key.userIconUrl, DefaultAvatar);
    } catch (err) {
      if (typeof err === 'object') {
        setErrorMessage(err.message);
      } else {
        setErrorMessage(err);
      }
    }
  };

  const handleForgotPassword = async (values) => {
    clearError();
    setIsSubmitting(true);
    try {
      await forgotPasswordVerification(values.username);
      setIsSubmitting(false);
      if (setEnableChangePassword) {
        setEnableChangePassword({ success: true, username: values.username });
      }
    } catch (error) {
      const err = getErrorMessage(error);
      setErrorMessage(err);
      if (setEnableChangePassword) setEnableChangePassword({ error: true });
    }
    setIsSubmitting(false);
  };
  const handleLoggedInNavigation = async () => {
    store(key.loggedIn, 'true');
    store(key.userIconUrl, DefaultAvatar);
    store(key.migrated, 'true');
    updateAWSConfig();
    await updateAuth();
    setIsSubmitting(false);
    handleSignInNavigation(pageVariant.static === variantFromContext);
  };
  const handleSignIn = (async (values) => {
    setIsSubmitting(true);
    clearError();
    if (disableSocialLogin) {
      disableSocialLogin(true);
      try {
        await signIn(values.username, values.password);
        if (disableSocialLogin) disableSocialLogin(false);
        await handleLoggedInNavigation();
      } catch (err) {
        store(key.loggedIn, 'false');
        const message = decodeURIComponent(err.message);
        const parsedData = message.substring(
          message.lastIndexOf('[') + 1,
          message.lastIndexOf(']') - 1,
        );
        if (!parsedData) {
          const error = getErrorMessage(err);
          setErrorMessage(error);
        } else {
          const {
            error,
          } = JSON.parse(parsedData);
          const errMsg = getErrorMessage(error);
          setErrorMessage(errMsg);
        }
        setIsSubmitting(false);
      }
      disableSocialLogin(false);
    }
  });

  const handleSignup = (async (values) => {
    setIsSubmitting(true);
    clearError();
    if (disableSocialLogin) {
      disableSocialLogin(true);
      try {
        updateCredentials(values.username, values.password, values.email);
        const result = await signUp(values.username, values.password, values.email);
        await handleLoggedInNavigation();
        await verifyUserEmail(result);
      } catch (err) {
        handleUserMergeOnError(err);
        setIsSubmitting(false);
      }
      disableSocialLogin(false);
    }
  });

  const getOnSubmitAction = (values) => {
    setShowError(true);
    if (variant === 'signup') {
      return handleSignup(values);
    } if (variant === 'login') {
      return handleSignIn(values);
    }
    return handleForgotPassword(values);
  };

  if (promptFlag) {
    return (
      <MergeUserAccount
        togglePrompt={setPromptFlag}
        mergeUserAccounts={mergeUserAccounts}
        existingLogins={existingLogin}
        handleSocialLogin={handleSocialLogin}
      />
    );
  }
  return (
    <>
      {
        errorMessage ? (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        ) : null
      }
      {
        <>
          <Formik
            initialValues={getInitialValues(variant)}
            validationSchema={schema}
            validateOnBlur={showError}
            validateOnChange={showError}
            onSubmit={getOnSubmitAction}
          >
            <Form>
              <Field
                type="username"
                name="username"
                placeholder={I18n.get(variant === 'forgot' ? placeholder.usernameOrEmail : placeholder.username)}
                component={p => <TextField {...p} />}
                variant="outlined"
                margin="dense"
                fullWidth
                autoFocus
                disabled={isSubmitting}
              />
              {
                variant === 'signup' ? (
                  <Field
                    type="email"
                    name="email"
                    placeholder={I18n.get(placeholder.email)}
                    component={TextField}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={isSubmitting}
                  />
                ) : null
              }
              {
                variant !== 'forgot' ? (
                  <Field
                    type="password"
                    name="password"
                    placeholder={I18n.get(placeholder.password)}
                    component={TextField}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={isSubmitting}
                  />
                ) : null
              }
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                {
                  isSubmitting ? (
                    <CircularProgress
                      color="secondary"
                      size={24}
                    />
                  ) : I18n.get(getActionName())
                }
              </Button>
            </Form>

          </Formik>

        </>
      }
    </>
  );
};

export default AuthInput;
