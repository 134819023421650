// @flow
/* --------------------- Globals -------------------- */
import React from 'react';

/* -----------------Third parties--------------- */
import { I18n } from '@aws-amplify/core';

/* -----------------UI--------------- */
import {
  Button,
  Typography,
  Box,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

/* -----------------Functions--------------- */
import { getFooterText } from 'common/auth/functions';

/* -----------------Props--------------- */
import type {
  EmptyAction,
} from 'common/types';

/* -----------------Styles--------------- */
const useStyles = makeStyles(theme => ({
  secondaryPrompt: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    margin: theme.spacing(0, 0, 2, 1),
  },
  secondaryLink: {
    color: theme.palette.secondary.main,
    textTransform: 'initial',
    margin: theme.spacing(0, 0, 2, 0),
  },
}));

/* -----------------Props--------------- */
type Props = {
  variant: string,
  primaryAction?: EmptyAction,
  secondaryAction: EmptyAction,
  showContactSupport?: boolean
}

const AuthFooter = (props: Props) => {
  const {
    variant,
    secondaryAction,
    primaryAction,
    showContactSupport = true,
  } = props;
  const classes = useStyles();

  const property = getFooterText(variant);

  const {
    primaryText,
    primaryActionName,
    secondaryText,
    secondaryActionName,
  } = property;

  const linkView = (name, action, actionName) => (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="body2" className={classes.secondaryPrompt}>
        {name}
      </Typography>
      <Button
        className={classes.secondaryLink}
        variant="text"
        onClick={action}
      >
        {I18n.get(actionName)}
      </Button>
    </Box>
  );
  return (
    <>
      {
        variant !== 'signup' && showContactSupport ? (
          linkView(primaryText, primaryAction, primaryActionName)
        ) : null
      }
      {
        linkView(secondaryText, secondaryAction, secondaryActionName)
      }
    </>
  );
};

export default AuthFooter;
