// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------Third parties--------------- */
import { I18n } from '@aws-amplify/core';

/* -----------------UI--------------- */
import {
  Typography,
  Button,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

/* -----------------Constants--------------- */
import {
  socialAuth,
  facebookAuth,
} from 'common/auth/constants';

/* -----------------Styles--------------- */
const useStyles = makeStyles(theme => ({
  disabledButton: {
    color: theme.palette.grey.light,
  },
  secondaryLink: {
    color: theme.palette.secondary.main,
    textTransform: 'initial',
  },
  actionButton: {
    color: theme.palette.secondary.main,
    textTransform: 'initial',
    margin: 10,
  },
}));

/* -----------------Props--------------- */
type Props = {
  togglePrompt: (v: boolean) => void,
  handleSocialLogin: (provider: string) => Promise<void>,
  existingLogins: string[],
};

const getPromptBody = (existingLoginType: string[]) => {
  const loginType = existingLoginType[0];
  if (loginType) {
    return {
      loginBtnText: `Continue with ${loginType}`,
      promptBody: `This email address is already in use via ${loginType}. Would you like to login with ${loginType}?`,
      providerType: loginType === 'Apple' ? socialAuth.apple : loginType,
    };
  }
  return {
    loginBtnText: socialAuth.login,
    promptBody: facebookAuth.userFlow,
    providerType: '',
  };
};

/* -----------------Component--------------- */

const MergeUserAccount = (props: Props) => {
  const {
    togglePrompt, handleSocialLogin, existingLogins,
  } = props;
  const classes = useStyles();
  const {
    providerType,
    promptBody,
    loginBtnText,
  } = getPromptBody(existingLogins);
  return (
    <>
      <Typography component="h1" variant="h5" align="center">
        {I18n.get(facebookAuth.emailTaken)}
      </Typography>
      <p>
        {I18n.get(promptBody)}
      </p>
      <div className={classes.promptAction}>
        <Button
          className={classes.actionButton}
          variant="text"
          onClick={() => handleSocialLogin(providerType)}
        >
          {I18n.get(loginBtnText)}
        </Button>
        <Button
          className={classes.actionButton}
          variant="text"
          onClick={() => { togglePrompt(false); }}
        >
          {I18n.get(facebookAuth.cancel)}
        </Button>
      </div>
    </>
  );
};

export default MergeUserAccount;
