// @flow
/* ------------------- Globals ------------------ */
import * as Yup from 'yup';

/* -------------------- Constants ----------------- */
import { error, authExpression } from './constants';

/* -------------------------- Schema -------------------- */
// TODO: Make alphanumeric and underscores only
const signUpValidationSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, error.username.minLength)
    .max(30, error.username.maxLength)
    .required(error.username.invalid)
    .matches(authExpression.username, error.username.invalidChar),
  password: Yup.string()
    .min(8, error.password.minLength)
    .max(50, error.password.maxLength)
    .required(error.password.invalid)
    .matches(authExpression.password, error.password.invalidChar),
  email: Yup.string()
    .email(error.email.invalid)
    .required(error.email.invalid)
    .matches(authExpression.email, error.email.invalid),
});

const signInValidationSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, error.username.minLength)
    .max(30, error.username.maxLength)
    .required(error.emailOrUsername.enter),
  password: Yup.string()
    .min(6, error.password.minLength)
    .max(50, error.password.maxLength)
    .required(error.password.enter),
});

const resetPasswordValidationSchema = Yup.object().shape({
  code: Yup.string()
    .required(error.enterCode),
  password: Yup.string()
    .min(6, error.password.minLength)
    .max(50, error.password.maxLength)
    .required(error.password.enter)
    .matches(authExpression.password, error.password.invalid),
});

const forgotPasswordValidationSchema = Yup.object().shape({
  username: Yup.string()
    .required(error.emailOrUsername.required),
});

export {
  signUpValidationSchema,
  signInValidationSchema,
  resetPasswordValidationSchema,
  forgotPasswordValidationSchema,
};
